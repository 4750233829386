import React, { useMemo, useEffect, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { graphql } from 'gatsby'
import { withTheme } from 'styled-components'

import { hashCode } from '@agnostack/lib-utils-js'

import { GlobalDispatch, GLOBAL_ACTIONS, TITLE_BOOK_MEETING } from '../util'
import { SEO } from '../components/atoms'

import Hero from '../components/molecules/Hero'
import ScheduleModal from '../components/molecules/ScheduleModal'
import Integrations from '../components/molecules/Integrations'
import Features from '../components/molecules/Features'
import LearnMore from '../components/molecules/LearnMore'
import Customers from '../components/molecules/Customers'
import Apps from '../components/molecules/Apps'

export const query = graphql`
  query bookMeetingPageQuery($appVersion: String!) {
    site {
      siteMetadata {
        tagline
      }
    }
    allMdx(filter: { frontmatter: { version: { eq: $appVersion } } }) {
      edges {
        node {
          body
          frontmatter {
            version
            date
            until
            category
            template
            tags
            title
            link
          }
        }
      }
    }
  }
`

export default withTheme(({
  location: {
    pathname,
  } = {},
  data: {
    site: {
      siteMetadata: {
        tagline,
      } = {},
    } = {},
    ...data
  } = {},
  pageContext: {
    fragments,
  } = {},
}) => {
  const dispatch = useContext(GlobalDispatch)
  const guid = useMemo(() => (pathname ? `${hashCode(pathname)}-booking` : uuidv4()), [pathname])

  useEffect(() => {
    dispatch({
      type: GLOBAL_ACTIONS.DISPATCH_MODAL,
      id: guid,
      visible: true,
      title: TITLE_BOOK_MEETING,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SEO title={`${TITLE_BOOK_MEETING}: ${tagline}`} keywords="book meeting" />
      <Hero
        relative
        mode="fullscreen"
        format="medium"
        data={data}
      />
      <Customers
        mode="overlay"
        format="light"
        backdrop="light"
      />
      <Features offset format="light" />
      <Integrations relative format="default" />
      <Apps relative format="light" fragments={fragments} />
      <LearnMore
        id="learn-more"
        mode="overlay"
        max="sm"
        format="light"
      />
      <ScheduleModal id={guid} />
    </>
  )
})
